import { useQuery } from 'react-query'
import { Item } from '@/domains'
import { SkuRuleItem } from '@/services/rules/RulesService.types'
import { loadAllItems } from '@/services/itemV2/ItemService'

export const useGetLoadAllItemsByRuleId = ({
	skuIds,
}: {
	skuIds?: SkuRuleItem[]
}): {
	data: Item[] | undefined
	isLoading: boolean
} => {
	const { data, isLoading } = useQuery(
		['load-all-items-by-rule', skuIds],
		() => {
			const itemIds = skuIds?.map((item) => item.itemId) ?? []

			return loadAllItems({
				itemIds,
			})
		},
		{ enabled: !!skuIds?.length },
	)

	return { data: data?.items, isLoading }
}
