import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { createEvent, createStore } from 'effector'

// State

export interface CreateRedeemableItemState {
	isOpened: boolean
	atInitialState: boolean
	isSearching: boolean
	isPublishing: boolean
	errorOnPublish: boolean
	errorOnItems: boolean
	errorCustomMessage?: string
	item: RedeemableItem | null
	publishedSuccessfully: boolean
	variants: RedeemableItem[]
	cppLimits?: {
		min: number
		max: number
	}
	currentTransaction?: {
		pricePerPoint?: number
		status?: string
	}
}

export const initialState: CreateRedeemableItemState = {
	isOpened: false,
	atInitialState: true,
	isSearching: false,
	isPublishing: false,
	errorOnPublish: false,
	errorOnItems: false,
	errorCustomMessage: undefined,
	item: null,
	publishedSuccessfully: false,
	variants: [],
	cppLimits: undefined,
	currentTransaction: undefined,
}

// Store

export const $createRedeemableItemStore = createStore(initialState)

// Events

export const CreateRedeemableItemStoreEvents = {
	onOpen: createEvent('onOpen'),
	onClose: createEvent('onClose'),
	onSearching: createEvent('onSearching'),
	onPublishing: createEvent('onPublishing'),
	errorOnPublish: createEvent('errorOnPublish'),
	errorOnItems: createEvent('errorOnItems'),
	setCustomErrorMessage: createEvent<string>(),
	setItem: createEvent<RedeemableItem | null>(),
	published: createEvent('published'),
	reset: createEvent('reset'),
	setVariants: createEvent<RedeemableItem>(),
	itemNotFound: createEvent('itemNotFound'),
}

// Handlers

const onOpen = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({ ...state, isOpened: true })
const onClose = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({ ...state, isOpened: false })
const onSearching = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({
	...state,
	isSearching: true,
	atInitialState: false,
	errorOnPublish: false,
	errorOnItems: false,
	errorCustomMessage: undefined,
	item: null,
})
const onPublishing = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({ ...state, isPublishing: true })
const errorOnPublish = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({
	...state,
	errorOnPublish: true,
	isPublishing: false,
})
const errorOnItems = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({
	...state,
	errorOnItems: true,
	isSearching: false,
	item: null,
})
const setCustomErrorMessage = (
	state: CreateRedeemableItemState,
	customErrorMessage: string,
): CreateRedeemableItemState => ({
	...state,
	errorCustomMessage: customErrorMessage,
})
const itemNotFound = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({
	...state,
	errorOnItems: false,
	isSearching: false,
	item: null,
})
const setItem = (state: CreateRedeemableItemState, item: RedeemableItem | null): CreateRedeemableItemState => ({
	...state,
	isSearching: false,
	errorOnItems: false,
	item,
})
const published = (state: CreateRedeemableItemState): CreateRedeemableItemState => ({
	...state,
	publishedSuccessfully: true,
})

const setVariants = (state: CreateRedeemableItemState, variant: RedeemableItem): CreateRedeemableItemState => ({
	...state,
	variants: [...state.variants.filter((v) => v.id !== variant.id), variant],
})

$createRedeemableItemStore
	.on(CreateRedeemableItemStoreEvents.onOpen, onOpen)
	.on(CreateRedeemableItemStoreEvents.onClose, onClose)
	.on(CreateRedeemableItemStoreEvents.onSearching, onSearching)
	.on(CreateRedeemableItemStoreEvents.onPublishing, onPublishing)
	.on(CreateRedeemableItemStoreEvents.errorOnPublish, errorOnPublish)
	.on(CreateRedeemableItemStoreEvents.errorOnItems, errorOnItems)
	.on(CreateRedeemableItemStoreEvents.setCustomErrorMessage, setCustomErrorMessage)
	.on(CreateRedeemableItemStoreEvents.setItem, setItem)
	.on(CreateRedeemableItemStoreEvents.published, published)
	.on(CreateRedeemableItemStoreEvents.setVariants, setVariants)
	.on(CreateRedeemableItemStoreEvents.itemNotFound, itemNotFound)
	.reset(CreateRedeemableItemStoreEvents.reset)
