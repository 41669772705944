import { SettingDefaultConfiguration } from '@/domains/settings'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { PricePerPointConfiguration, PricePerPointConfigurationStoreEvents, SettingsConfigurationStore } from '@/stores'
import {
	UpdateInitialBalanceUseCase,
	UpdatePricePerPointConfigurationUseCase,
	UpdateRedeemLimitUseCase,
} from '@/usecase'
import { format, unformat } from '@/utils/currencyHelper'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { isNonValue } from '@/utils/object'
import { useStore } from 'effector-react'
import { useCallback, useState } from 'react'
import * as FeatureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '@/hooks'
import { useIsEditingAllowed } from './useIsEditingAllowed'

export type UseZoneConfigurationEditResult = {
	isLoading: boolean
	hasError: boolean
	settingsData?: SettingDefaultConfiguration
	updateData: (formData: SettingDefaultConfiguration | PricePerPointConfiguration) => Promise<void>
}

export const useZoneConfigurationEdit = (): UseZoneConfigurationEditResult => {
	const [isLoading, setIsLoading] = useState(false)
	const [hasError, setHasError] = useState(false)

	const selectedZone = useCurrentZone()
	const { selectedTierKey } = useCurrentTier()
	const { settingsData } = useStore(SettingsConfigurationStore)
	const { isPricePerPointAllowed } = useIsEditingAllowed()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()

	const updateInitialBalance = useCallback(
		async (initialBalance?: number) => {
			if (isNonValue(initialBalance)) {
				return null
			}

			return UpdateInitialBalanceUseCase.execute({
				selectedTierKey: selectedTierKey!,
				selectedZone: selectedZone!,
				formValues: {
					initialBalance: initialBalance!,
				},
			})
		},
		[selectedTierKey, selectedZone],
	)

	const updateRedeemLimit = useCallback(
		async (redeemLimit?: number) => {
			if (isNonValue(redeemLimit)) {
				return null
			}
			return UpdateRedeemLimitUseCase.execute({
				vendorId: getDefaultVendorIdOfCurrentZone(),
				selectedTierKey: selectedTierKey!,
				redeemLimit: redeemLimit!,
			})
		},
		[selectedTierKey],
	)

	const updatePricePerPoint = useCallback(
		async (pricePerPoint?: number) => {
			if (!isPricePerPointAllowed || isNonValue(pricePerPoint)) {
				return null
			}

			const pricePerPointValue = unformat(format(pricePerPoint))
			if (!pricePerPointValue) {
				return null
			}

			return UpdatePricePerPointConfigurationUseCase.execute({
				vendorId: getDefaultVendorIdOfCurrentZone(),
				pricePerPoint: pricePerPointValue,
			})
		},
		[isPricePerPointAllowed],
	)

	const updateData = useCallback(
		async (formData: SettingDefaultConfiguration | PricePerPointConfiguration) => {
			try {
				setIsLoading(true)
				setHasError(false)

				const defaultSettingsFormData = <SettingDefaultConfiguration>formData
				const pppSettingsFormData = <PricePerPointConfiguration>formData

				await Promise.all([
					updateInitialBalance(defaultSettingsFormData.initialBalance),
					updateRedeemLimit(defaultSettingsFormData.redeemLimit),
					updatePricePerPoint(pppSettingsFormData.pricePerPoint),
				])

				PricePerPointConfigurationStoreEvents.valueChangedSuccessfully()
			} catch (error) {
				setHasError(true)
				if (error instanceof Error) {
					sendAnalyticsServiceEventError({ failureReason: error.message, screenName: 'Default Price Per Point' })
				}
				throw error
			} finally {
				setIsLoading(false)
			}
		},
		[sendAnalyticsServiceEventError, updateInitialBalance, updatePricePerPoint, updateRedeemLimit],
	)

	return {
		isLoading,
		hasError,
		settingsData,
		updateData,
	}
}
