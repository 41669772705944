import React, { useState, useEffect } from 'react'
import { useStore } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { CurrentTransactionStatusEnum } from '@/domains/redeemable/RedeemableItem'
import { $defaultCppStore } from '@/stores/redeemableItems/DefaultCPPStore'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { EditDefaultCppCard } from './components/EditDefaultCppCard/EditDefaultCppCard'
import { EditDefaultCppDialog } from './components/EditDefaultCPPDialog/EditDefaultCPPDialog'
import { CancelRequestDialog } from '../CancelRequestDialog/CancelRequestDialog'
import { useDefaultCpp } from '../../hooks/useDefaultCpp'

interface DefaultCpp {
	hasRedeemablesDefaultCPPPermission?: boolean
}

export const DefaultCpp: React.FC<DefaultCpp> = ({ hasRedeemablesDefaultCPPPermission }) => {
	const isRedeemableApprovalFlowEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_APPROVAL_FLOW)

	const { reloadAtTime } = useStore($defaultCppStore)

	const { loadDefaultCppData, defaultCppData, isDefaultCppLoading } = useDefaultCpp()

	const [isEditDefaultCppOpened, setIsEditDefaultCppOpened] = useState(false)
	const [isCancelRequestOpened, setIsCancelRequestOpened] = useState(false)

	const toast = useToast()
	const { t } = useTranslation()

	const isDefaultCppProcessing = defaultCppData?.update.status === CurrentTransactionStatusEnum.PROCESSING

	const handleOpenDialog = () => {
		if (isDefaultCppProcessing && isRedeemableApprovalFlowEnabled) {
			setIsCancelRequestOpened(true)
		} else {
			setIsEditDefaultCppOpened(true)
		}
	}

	const handleUpdateSuccess = () => {
		setIsEditDefaultCppOpened(false)
		toast.notify({
			type: TypeToast.SUCCESS,
			message: t('redeemable:UPDATE_CPP.TOAST_SUCCESS'),
		})
	}

	useEffect(() => {
		loadDefaultCppData(hasRedeemablesDefaultCPPPermission && isRedeemableApprovalFlowEnabled)
	}, [reloadAtTime, isRedeemableApprovalFlowEnabled, loadDefaultCppData])

	return (
		<>
			<EditDefaultCppCard
				isRedeemableApprovalFlowEnabled={isRedeemableApprovalFlowEnabled}
				onEdit={handleOpenDialog}
				newDefaultCppContent={defaultCppData}
				loading={isDefaultCppLoading}
			/>
			<EditDefaultCppDialog
				isRedeemableApprovalFlowEnabled={isRedeemableApprovalFlowEnabled}
				open={isEditDefaultCppOpened}
				onClose={() => setIsEditDefaultCppOpened(false)}
				onUpdate={handleUpdateSuccess}
			/>
			<CancelRequestDialog
				isOpened={isCancelRequestOpened}
				requestInfo={{
					id: defaultCppData?.id ?? '',
					pricePerPoint: defaultCppData?.defaultCpp ?? 0,
					updatedPricePerPoint: defaultCppData?.newDefaultCpp ?? 0,
					type: 'DEFAULT',
				}}
				onClose={() => setIsCancelRequestOpened(false)}
			/>
		</>
	)
}
