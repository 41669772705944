import { TypeToast } from 'admin-portal-shared-services'
import { AxiosError } from 'axios'
import ToastService from 'admin-portal-shared-services/dist/services/toast/ToastService'
import { SkuRuleItem } from '../RulesService.types'

export const normalizeSkus = ({
	items,
	vendorIdSettings,
}: {
	items?: SkuRuleItem[]
	vendorIdSettings: string
}): SkuRuleItem[] | undefined => {
	return items?.map((sku) => ({
		vendorId: sku.vendorId ? sku.vendorId : vendorIdSettings,
		vendorItemId: sku.vendorItemId,
		sku: sku.sku,
		itemId: sku.itemId,
		image: sku?.image,
	}))
}

export const handleEarningRulesError = ({ err, toast }: { toast: ToastService; err: AxiosError }): void => {
	let message = 'An error occurred. Try again.'

	const jsonStart = err.message.indexOf('{')
	const jsonEnd = err.message.lastIndexOf('}') + 1

	if (jsonStart !== -1 && jsonEnd !== 0) {
		try {
			const errorData = JSON.parse(err.message.substring(jsonStart, jsonEnd))
			if (errorData.code === 33 && errorData.clientResponse?.status === 422) {
				message = 'Duplicated items. Item is already being used on other rule.'
			}
		} catch (e) {
			message = 'An error occurred. Try again.'
		}
	}

	toast.notify({
		type: TypeToast.ERROR,
		message,
	})
}
