import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography } from '@material-ui/core'

import { ProgramCombo } from '@/domains/program'
import DataTable, { DataTableRef, RowSelection } from '@/components/tables/DataTable'
import { Redeemable } from '@/domains'
import { formatDate } from '@/utils/date'

import { ComboType, RedeemableType } from '@/domains/enums'
import useIsCombosBulkUpdateEnabled from '@/hooks/combo/useIsCombosBulkUpdateEnabled'
import { TableActions } from '@/components/tables/DataTable/model/TableActions'
import EditRedeemableDialog from '../EditRedeemableDialog/EditRedeemableDialog'
import RemoveRedeemableDialog from '../RemoveRedeemableDialog/RemoveRedeemableDialog'
import useStyles from '../CombosList/CombosListStyle'
import EditComboDialog from '../EditComboDialog/EditComboDialog'
import RemoveComboDialog from '../RemoveComboDialog/RemoveComboDialog'

type ComboUnpublishedTabViewProps = {
	onRemoveCombo: (selectedCombo: Redeemable | ProgramCombo, rowIndex: number) => void
	onRemoveMultipleCombos: (selectedCombos: Redeemable[], rowIndexes: number[]) => void
	onClickDownloadCsv: () => void
	combos: Redeemable<ComboType>[] | ProgramCombo[]
	isDisabledDownloadButton: boolean
	isEnabledEditAndRemoveMultiplesCombos: boolean
	isLoading: boolean
	pageSize: number
	showError: boolean
}

const ComboUnpublishedTabView: React.FC<ComboUnpublishedTabViewProps> = ({
	onClickDownloadCsv,
	onRemoveCombo,
	onRemoveMultipleCombos,
	isDisabledDownloadButton,
	isEnabledEditAndRemoveMultiplesCombos,
	showError,
	isLoading,
	combos,
	pageSize,
}) => {
	const isCombosBulkUpdateEnabled = useIsCombosBulkUpdateEnabled()
	const dataTableRef = React.useRef<DataTableRef>(null)
	const { t } = useTranslation()
	const classes = useStyles()
	const itemsPerPage = [10, 25, 50, 75, 100]

	const getActions = () => {
		const actions = [
			{
				id: 'remove-all',
				label: t('combo:ROW_SELECTION.REMOVE_ALL'),
				onClick: (rows: Redeemable<RedeemableType>[], indexes: number[]) => {
					onRemoveMultipleCombos(rows, indexes)
				},
			},
		]

		return actions
	}
	const rowSelectionConfig: RowSelection = {
		clearSelectionButton: {
			id: 'clear-selection',
			label: t('combo:ROW_SELECTION.CLEAR_SELECTION'),
		},
		itemsCountLabel: {
			many: t('combo:ROW_SELECTION.COUNT_LABEL.MANY'),
			one: t('combo:ROW_SELECTION.COUNT_LABEL.ONE'),
		},
		actions: getActions(),
	}

	const getTableActions = (): TableActions[] => {
		const deleteOption: TableActions[] = [
			{
				label: `${t('combo:LIST.ACTION_REMOVE')}`,
				type: 'delete',
				fn: onRemoveCombo,
			},
		]

		return deleteOption
	}

	return (
		<>
			<Grid container alignItems="center">
				<Grid item container xs={isCombosBulkUpdateEnabled ? 8 : 12}>
					<Box paddingY="16px">
						<Typography>{t('combo:DT_COMBOS_TEXT_TAB.UNPUBLISHED')}</Typography>
					</Box>
				</Grid>
				{isCombosBulkUpdateEnabled && (
					<Grid item container xs={4} justifyContent="flex-end">
						<Box paddingY="16px">
							<Button
								type="button"
								color="primary"
								variant="outlined"
								id="downloadDtCombos"
								onClick={onClickDownloadCsv}
								disabled={isDisabledDownloadButton}
								className={classes.downloadButton}
							>
								{t('combo:DT_COMBOS.DOWNLOAD_DT_COMBO')}
							</Button>
						</Box>
					</Grid>
				)}
			</Grid>
			{isCombosBulkUpdateEnabled ? (
				<>
					<EditRedeemableDialog />
					<RemoveRedeemableDialog dataTableRef={dataTableRef} />
				</>
			) : (
				<>
					<EditComboDialog />
					<RemoveComboDialog dataTableRef={dataTableRef} />
				</>
			)}
			<DataTable
				id="dt-combos"
				searchConfigs={{
					id: 'search-combo',
					placeholder: `${t('combo:LIST.SEARCH_COMBOS.UNPUBLISHED')}`,
					searchBy: [isCombosBulkUpdateEnabled ? 'redeemableId' : 'comboId', 'points'],
				}}
				columns={[
					{
						label: `${t('combo:LIST.COLUMN_UNPUBLISHED_ID')}`,
						dataKey: isCombosBulkUpdateEnabled ? 'redeemableId' : 'comboId',
						width: '30%',
					},
					{
						label: `${t('combo:LIST.COLUMN_POINTS')}`,
						dataKey: 'points',
						width: '20%',
					},
					{
						label: `${t('combo:LIST.COLUMN_LAST_UPDATE')}`,
						dataKey: 'lastModified',
						width: '40%',
						format: (date): string => (date ? formatDate(date as Date, t('DATE_FORMAT_TABLE')) : ''),
					},
				]}
				data={combos}
				tableActions={getTableActions()}
				configs={{
					itemsPerPage: pageSize,
					sortEnabled: [isCombosBulkUpdateEnabled ? 'redeemableId' : 'comboId', 'points', 'lastModified'],
					actionWidth: '1%',
					itemsPerPageOptions: itemsPerPage,
					style: {
						emptyMessage: {
							showIcon: true,
						},
					},
				}}
				ref={dataTableRef}
				loading={isLoading}
				showError={showError}
				emptyMessage={t('combo:NOTIFICATION.EMPTY_MESSAGE')}
				rowSelection={isEnabledEditAndRemoveMultiplesCombos ? rowSelectionConfig : undefined}
			/>
		</>
	)
}

export default ComboUnpublishedTabView
