import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import convert from 'converters/ChallengeToChallengeHistory'
import { ChallengeHistoryEvents, PageLevelEvents } from '@/stores'
import { ChallengeEvents, ChallengeStore, SkuBox } from '@/stores/challengeCreation'
import { Challenge, Group, ChallengeHistory, ChallengeFiles } from '@/domains'
import { ChallengeExecutionMethod, EditDialog } from '@/domains/enums'
import { BASE_ROUTE } from 'utils/constants'
import AnalyticsService from 'services/analytics/AnalyticsService'
import {
	ChallengeUseCase as UpsertChallengeUseCase,
	VerifyEnrollmentUseCase,
	LoadChallengesWithGroupsUseCase as loadChallengesWithGroupsUseCase,
	GlobalUseCase as globalUseCase,
	LoadChallengeAcceptedPocsUseCase,
} from '@/usecase'

import MenuInteraction from 'domains/analytics/MenuInteraction'
import ButtonInteraction from 'domains/analytics/ButtonInteraction'
import ScreenName from 'domains/analytics/ScreenName'
import { useTranslation } from 'react-i18next'
import * as FeatureToggle from 'utils/featureToggle'

import { challengeSkusToItems, ChallengeConverters } from '@/converters'
import { useStoreMap } from 'effector-react'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { useFeatureToggleV2 } from '@/hooks/toggle/useFeatureToggle'
import ChallengeHistoryDetailView, { AcceptedPocItem } from './ChallengeHistoryDetailView'
import useAuditLogChallengeEdited from '../../hooks/useAuditLogChallengeEdited'

type TParams = {
	id: string
}

const screenNameObject = {
	[EditDialog.Setup]: ScreenName.ChallengeSetup,
	[EditDialog.Filter]: ScreenName.ChallengeFilter,
	[EditDialog.Details]: ScreenName.ChallengeDetails,
	[EditDialog.Name]: ScreenName.ChallengeSetup,
	[EditDialog.None]: '',
}

const ChallengeHistoryDetail: React.FC = () => {
	const match = useRouteMatch<TParams>()
	const userInfo = useGetUserInfo()
	const [loading, setLoading] = useState(true)
	const [initialChallenge, setInitialChallenge] = useState<Challenge | undefined>(undefined)
	const [initialGroups, setInitialGroups] = useState<Group[] | null>(null)
	const [challenge, setChallenge] = useState(null as ChallengeHistory | null)
	const [acceptedPocs, setAcceptedPocs] = useState<AcceptedPocItem[]>([])
	const { sendAuditLogChallengeEdited } = useAuditLogChallengeEdited()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const history = useHistory()
	const { t } = useTranslation()
	const toast = useToast()

	const isCancelChallengesEnabled = useFeatureToggleV2(FeatureToggle.CANCEL_CHALLENGES)

	const activeEditDialog = useStoreMap({
		store: ChallengeStore,
		keys: [],
		fn: (state) => state.activeEditDialog,
	})

	const toggleEditDialog = (successNotification: boolean, editDialog: EditDialog): void => {
		if (successNotification) {
			PageLevelEvents.showNotification({
				notificationType: 'success',
				message: t('challenge:SUCCESSFUL_EDITION'),
			})
		} else {
			PageLevelEvents.hideNotification()
		}
		if (editDialog !== EditDialog.None) {
			ChallengeEvents.editChallenge({ activeEditDialog: editDialog, challengeForEditing: initialChallenge! })
			ChallengeEvents.setChallengeSetupFormData(
				ChallengeConverters.challengeToChallengeSetupFormData(initialChallenge!),
			)
			ChallengeEvents.setChallengeFilterFormData(
				ChallengeConverters.challengeToChallengeFilterFormData(initialChallenge!),
			)
			ChallengeEvents.setChallengeDetailsFormData(
				ChallengeConverters.challengeToChallengeDetailsFormData(initialChallenge!),
			)

			sendAnalyticsServiceEventButtonClickedEvent(
				`${screenNameObject[editDialog]} - ${challenge?.title} - ${challenge?.executionMethod}`,
				ButtonInteraction.Edit,
				`${t('common:EDIT')}`,
			)
		} else {
			ChallengeEvents.closeEditChallengeDialog()
		}
	}

	const handleSetChallenge = (challengeNoGroups: Challenge, groups: Group[] | null) => {
		const convertedChallenge = convert(challengeNoGroups, groups)
		setChallenge(convertedChallenge)
		setInitialChallenge(challengeNoGroups)
	}

	const handleSubmitEditDialog = async (editedChallenge: Challenge, files: ChallengeFiles = {}): Promise<void> => {
		if (
			[
				ChallengeExecutionMethod.Purchase,
				ChallengeExecutionMethod.PurchaseMultiple,
				ChallengeExecutionMethod.VolumeFixed,
			].includes(editedChallenge.executionMethod)
		) {
			const { items, skus } = challengeSkusToItems(editedChallenge.skus as SkuBox[], editedChallenge.items || [])
			editedChallenge.skus = skus
			editedChallenge.items = items
		}

		const newChallange = await UpsertChallengeUseCase.execute({
			challenge: editedChallenge,
			files,
			initialChallenge,
			analyticsProps: userInfo,
		}).catch(() => {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('common:ERROR_MESSAGE.GENERIC_ERROR'),
			})
		})
		if (!newChallange) {
			throw new Error('Error')
		}
		handleSetChallenge(newChallange, initialGroups)
		toggleEditDialog(true, EditDialog.None)
		history.push(`/membership/challenges/${newChallange.id}`)
		AnalyticsService.events.recordUpdated({
			form_name: `${ScreenName.Edit} ${screenNameObject[activeEditDialog]}`,
			record_type: newChallange.executionMethod,
			record_id: newChallange.id,
			record_additional_info: screenNameObject[activeEditDialog],
			screen_name: `${ScreenName.EditChallenge} - ${newChallange.title} - ${newChallange.executionMethod}`,
			...userInfo,
		})
		sendAuditLogChallengeEdited(newChallange)
	}

	const handleBreadcrumbBack = () => {
		AnalyticsService.events.menuInteraction({ menu_category: MenuInteraction.BackToChallengeHistory })
		history.push(`${BASE_ROUTE}/challenges`)
	}

	const loadAcceptedPocs = (challengeId: string) => {
		LoadChallengeAcceptedPocsUseCase.execute(challengeId)
			.then((pocList) => setAcceptedPocs(pocList))
			.catch(() => setAcceptedPocs([]))
	}

	const loadData = useCallback(
		async (challengeId: string) => {
			globalUseCase.load()
			try {
				const data = await loadChallengesWithGroupsUseCase.executeById(challengeId)
				if (data) {
					handleSetChallenge(data.challenge, data.groups)
					setInitialGroups(data.groups)
					loadAcceptedPocs(challengeId)
					if (isCancelChallengesEnabled) {
						ChallengeHistoryEvents.setChallengeAccepted(
							await VerifyEnrollmentUseCase.verifyChallengeAcceptance(challengeId).catch(() => null),
						)
					} else {
						ChallengeHistoryEvents.setChallengeAccepted(null)
					}
				}
			} finally {
				setLoading(false)
				globalUseCase.loadDone()
			}
		},
		[isCancelChallengesEnabled],
	)

	useEffect(() => {
		loadData(match.params.id)
	}, [loadData, match.params.id])

	useEffect(() => {
		return () => {
			ChallengeHistoryEvents.resetForChallengeHistory()
			PageLevelEvents.hideNotification()
			ChallengeEvents.reset()
		}
	}, [])

	return (
		<ChallengeHistoryDetailView
			challengeHistory={challenge}
			isLoading={loading}
			toggleEditDialog={toggleEditDialog}
			onSaveDialog={handleSubmitEditDialog}
			onBreadcrumbBack={handleBreadcrumbBack}
			acceptedPocs={acceptedPocs}
		/>
	)
}

export default ChallengeHistoryDetail
