import { SettingsLevel } from '@/domains/enums/settings'
import { ChangeConfigParams, updateConfiguration } from '@/services/settings/SettingsService'
import { AxiosResponse } from 'axios'

export type UpdateRedeemParams = {
	redeemLimit: number
	selectedTierKey: string
	vendorId: string
}

export async function execute({ redeemLimit, selectedTierKey, vendorId }: UpdateRedeemParams): Promise<AxiosResponse> {
	const body = {
		redeemLimit,
	}

	const level = SettingsLevel.VENDOR
	const params: ChangeConfigParams = {
		settingsId: vendorId,
		level,
		tiers: [selectedTierKey],
	}
	return updateConfiguration(params, body)
}
