import ItemResponse, { ItemErrorResponse } from '@/domains/Item/ItemResponse'
import { hasElements, splitIntoListOfList } from '@/utils/array'
import axios from '@/utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import { AxiosError } from 'axios'

const endPoint = `/rewards-admin-service/v2/items`
const log = logService()
const MAX_ELEMENTS_REQUEST = 100

type LoadItemProps = {
	itemIds: Array<string>
	isMultivendor?: boolean
}

function reduceTheResult(result: Array<ItemResponse>): ItemResponse {
	const base = {
		items: [],
	}
	return result.reduce((prevValue: ItemResponse, currValue: ItemResponse) => {
		return {
			items: [...prevValue.items, ...currValue.items],
		} as ItemResponse
	}, base)
}

function callLoadAll({ itemIds }: LoadItemProps): Promise<ItemResponse> {
	const params = {
		itemIds: itemIds.join(','),
		pageSize: MAX_ELEMENTS_REQUEST,
	}

	return axios
		.get<ItemResponse>(endPoint, {
			params,
		})
		.then((response) => {
			return response.data
		})
		.catch((error: AxiosError<ItemErrorResponse>) => {
			log.error(`Error endpoint: ${endPoint}`, error)

			if (error && error.response?.status === 404) {
				return {
					items: [],
				}
			}
			throw error
		})
}

async function loadAll({ itemIds }: LoadItemProps): Promise<ItemResponse> {
	if (!hasElements(itemIds)) {
		return callLoadAll({ itemIds })
	}

	const requests = splitIntoListOfList<string>(itemIds, MAX_ELEMENTS_REQUEST).map((list) =>
		callLoadAll({ itemIds: list }).catch((error) => {
			log.error(`Error loading items for list: ${list}`, error)
			return { items: [] }
		}),
	)

	const results = await Promise.all(requests)
	return reduceTheResult(results)
}

function loadAllItems({ itemIds }: LoadItemProps): Promise<ItemResponse> {
	log.error(`starts with ${itemIds.length}`)

	return loadAll({ itemIds })
}

export { loadAll, loadAllItems }
