import React, { useMemo } from 'react'
import { ICardOption } from '@/domains/option'
import { SKUProcess } from '@/domains/sku/SkuStep'
import { useTranslation } from 'react-i18next'
import { useFeatureToggleV2 } from '@/hooks/toggle/useFeatureToggle'
import { RadioCard } from './components/RadioCard/RadioCard'
import Styled from './RadioCards.styles'

export const RadioCards = () => {
	const { t } = useTranslation()

	const enableUploadCSV = useFeatureToggleV2('membership-earning-sku-step-csv')

	const options = useMemo<Array<ICardOption>>(() => {
		const values = [
			{
				label: t('rules:STEPS.PROCESS.SINGLE.LABEL'),
				subLabel: t('rules:STEPS.PROCESS.SINGLE.SUBLABEL'),
				value: SKUProcess.SINGLE_SKU,
			},
		]
		if (enableUploadCSV) {
			values.push({
				label: t('rules:STEPS.PROCESS.MULTIPLE.LABEL'),
				subLabel: t('rules:STEPS.PROCESS.MULTIPLE.SUBLABEL'),
				value: SKUProcess.MULTIPLE_SKU,
			})
		}
		return values
	}, [t, enableUploadCSV])

	return (
		<Styled.GridRadioCards>
			{options.map((option) => (
				<RadioCard key={option.value} value={option.value} title={option.label} subtitle={option.subLabel} />
			))}
		</Styled.GridRadioCards>
	)
}
