import { PermissionLevel } from '@/domains/enums'
import { useFeatureToggleV2 } from '@/hooks'
import useAuthorization from '@/hooks/authorization/useAuthorization'
import * as FeatureToggle from '@/utils/featureToggle'
import { useIsToggleRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import MembershipFeatures, { features } from 'domains/authorization/MembershipFeatures'

export type EditingAllowedResult = {
	isPricePerPointAllowed: boolean
	isRedeemableCPPPermission: boolean
}

export const useIsEditingAllowed = (): EditingAllowedResult => {
	const { isFeatureAuthorized } = useAuthorization()
	const togglePPP = useFeatureToggleV2(FeatureToggle.REDEEMABLE_ITEMS_MODULE)
	const isPricePerPointFeatureEnabled = useIsToggleRedeemableItemsEnabled()
	const redeemableCPPToggle = useFeatureToggleV2(FeatureToggle.REDEEMABLE_COST_PER_POINT_PERMISSION)

	const isRedeemableCPPPermission = isFeatureAuthorized(redeemableCPPToggle!, {
		feature: features.RedeemablesCPP as MembershipFeatures,
		permissionLevel: PermissionLevel.Delete,
	})

	const isPricePerPointAllowed =
		isFeatureAuthorized(togglePPP!, {
			feature: features.DTComboManagement as MembershipFeatures,
			permissionLevel: PermissionLevel.Delete,
		}) && isPricePerPointFeatureEnabled

	return {
		isPricePerPointAllowed,
		isRedeemableCPPPermission,
	}
}
