import { ScreenName } from '@/domains'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import { getCSVFileUseCase } from '@/usecase/redeemables/DownloadCSVUseCase'
import { Button } from '@hexa-ui/components'
import { Download } from '@hexa-ui/icons'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface ExportRedeemableItemButtonProps {
	className?: string
}

export const ExportRedeemableItemButton = ({ className }: ExportRedeemableItemButtonProps) => {
	const icon = useCallback(() => <Download size="large" />, [])
	const { t } = useTranslation()
	const toast = useToast()
	const { vendorId = '' } = useCurrentVendor() || {}
	const [isLoading, setIsLoading] = React.useState(false)
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()

	const handleDownloadClick = useCallback(async () => {
		setIsLoading(true)
		try {
			const csvFile = await getCSVFileUseCase(vendorId)
			const blob = new Blob([csvFile], { type: 'text/csv' })
			const downloadUrl = URL.createObjectURL(blob)

			const link = document.createElement('a')
			link.href = downloadUrl
			link.download = 'redeemableList.csv'
			link.click()

			URL.revokeObjectURL(downloadUrl)

			sendAnalyticsServiceEventButtonClickedEvent(ScreenName.RedeemablesList, 'Download CSV', 'Download CSV')

			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT.SUCCESS_DOWNLOAD'),
			})
		} catch (error) {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('redeemable:NOTIFICATION.EDIT.ERROR_DOWNLOAD'),
			})
		} finally {
			setIsLoading(false)
		}
	}, [t, toast, vendorId, sendAnalyticsServiceEventButtonClickedEvent])

	return (
		<Button
			icon={icon}
			onClick={handleDownloadClick}
			leading
			variant="secondary"
			size="medium"
			data-testid="redeemable-items-button-export"
			className={className}
			isLoading={isLoading}
			css={{ width: '187px' }}
		>
			{t('redeemable:BUTTON.DOWNLOAD_CSV')}
		</Button>
	)
}
