import * as Yup from 'yup'
import { MAX_SIZE_FILE_1MB } from '@/utils/constants'
import { Item } from '@/domains'
import SkuForm from '@/domains/sku/SkuForm'

export const getCsvValidateSchema = (messages: { required: string; fileSize: string; fileType: string }) => {
	return Yup.array()
		.of(
			Yup.mixed()
				.required(messages.required)
				.test('fileSize', messages.fileSize, (value) => {
					return value && value.size <= MAX_SIZE_FILE_1MB
				})
				.test('fileType', messages.fileType, (value) => {
					return value && ['.csv', 'csv', 'text/csv'].includes(value.type)
				}),
		)
		.required(messages.required)
}

export const parseCSVHandler = (results: Papa.ParseResult<unknown>): { error?: boolean; vendorItemIds?: string[] } => {
	const { data, meta } = results
	const vendorItemIds = data.map((item) => (item as { sku: string }).sku)
	const errorsConditions =
		(data.length === 0 && data.length > 5000) || meta.fields?.length !== 1 || !meta.fields?.includes('sku')

	return {
		error: errorsConditions,
		vendorItemIds: errorsConditions ? undefined : vendorItemIds,
	}
}

export const parseItemsToSkuTable = (items: Item[]): SkuForm[] => {
	return items.map((item) => ({
		id: item.sku,
		name: item.itemName ?? '',
		itemId: item?.id,
		image: item?.itemImage,
		containerDescription: item?.container.fullContainerDescription,
		sku: item?.sku,
		vendorItemId: item?.vendorItemId
	}))
}
