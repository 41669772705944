import { useCallback } from 'react'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { ComboEvents, SettingsConfigurationEvents } from '@/stores'
import { LoadDefaultConfigurationUseCase, LoadProgramUseCase } from '@/usecase'
import * as FeatureToggle from '@/utils/featureToggle'
import { useGetMultiplesFeatureToggles } from '../toggle/useGetMultiplesFeatureToggles'

export interface LoadCombosSettingsResult {
	loadCombosSettings: () => Promise<void>
	togglesReady: boolean
}

export const useLoadCombosSettings = (): LoadCombosSettingsResult => {
	const { selectedTierKey } = useCurrentTier()

	const { togglesReady, featuresListByToggleName } = useGetMultiplesFeatureToggles([
		FeatureToggle.ZONE_CONFIGURATION_MODULE,
	])

	const isSettingConfigByTier = featuresListByToggleName[FeatureToggle.ZONE_CONFIGURATION_MODULE]

	const loadProgramData = useCallback(async () => {
		const program = await LoadProgramUseCase.execute()
		if (!program) {
			throw new Error('No program!')
		}
		ComboEvents.setProgramDataForCombo(program)
	}, [])

	const loadDefaultConfiguration = useCallback(async () => {
		const settings = await LoadDefaultConfigurationUseCase.execute({
			selectedTierKey: selectedTierKey!,
		})
		if (!settings) {
			throw new Error('No settings!')
		}
		SettingsConfigurationEvents.setSettingsData(settings)
	}, [selectedTierKey])

	const loadData = useCallback(async (): Promise<void> => {
		try {
			ComboEvents.setCombosPageReady(false)
			if (isSettingConfigByTier) {
				await loadDefaultConfiguration()
			} else if (isSettingConfigByTier === false) {
				await loadProgramData()
			}
		} catch {
			ComboEvents.showErrorInCombosData()
		} finally {
			ComboEvents.setCombosPageReady(true)
		}
	}, [loadProgramData, loadDefaultConfiguration, isSettingConfigByTier])

	return {
		loadCombosSettings: loadData,
		togglesReady,
	}
}
