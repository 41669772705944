import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrentTransactionStatusEnum, RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useFeatureToggleV2 } from '@/hooks'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { MANAGE_REDEEMABLE } from '@/utils/constants'
import * as FeatureToggle from '@/utils/featureToggle'

import { IconButton, TextButton, Tooltip } from '@hexa-ui/components'
import { Edit2, RotateCw } from '@hexa-ui/icons'
import { CancelRequestDialog } from '@/routes/redeemableItems/components/CancelRequestDialog/CancelRequestDialog'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import RedeemableItemsListStyle from '../../../../RedeemableItemsListStyle'
import { useRedeemableItemActions } from '../../../../hooks/useRedeemableItemActions'
import DeleteRedeemableItems from '../../../DeleteRedeemableItens/DeleteRedeemableItems'

export const RedeemableItemActions = ({ item }: { item: RedeemableItem }) => {
	const isRedeemableApprovalFlowEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_APPROVAL_FLOW)
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])

	const { buildRedeemableAction, shouldDisableActions: shouldDisabledPPP } = useRedeemableItemActions()
	const { data } = usePricePerPointConfiguration()
	const actions = buildRedeemableAction(item)
	const disabled = shouldDisabledPPP(item)

	const { t } = useTranslation()
	const css = RedeemableItemsListStyle()

	const [isCancelRequestDialogOpened, setIsCancelRequestDialogOpened] = useState(false)

	const isPending = item.currentTransaction?.status === CurrentTransactionStatusEnum.PROCESSING
	const defaultCpp = data?.pricePerPoint

	const handleEditItemButtonAction = () => {
		if (isPending && isRedeemableApprovalFlowEnabled) {
			setIsCancelRequestDialogOpened(true)
		} else {
			actions.onEdit()
		}
	}

	const handleCancelRequestDialogClose = () => {
		setIsCancelRequestDialogOpened(false)
	}

	return (
		<div className={css.actions} data-testid="redeemable-item-actions">
			<CancelRequestDialog
				isOpened={isCancelRequestDialogOpened}
				requestInfo={{
					id: item?.currentTransaction?.transactionId ?? '',
					pricePerPoint: item?.pricePerPoint ?? defaultCpp ?? 0,
					updatedPricePerPoint: item?.currentTransaction?.pricePerPoint ?? 0,
					type: 'SKU',
				}}
				onClose={handleCancelRequestDialogClose}
			/>
			{actions.onReload ? (
				<ReloadItemButton action={actions.onReload} disabled={disabled} tooltipText={t('BUTTON.RELOAD')} />
			) : (
				!hasManageRedeemablePermission && (
					<EditItemButton action={handleEditItemButtonAction} disabled={disabled} tooltipText={t('BUTTON.EDIT')} />
				)
			)}
			<Tooltip placement="bottom" text={t('BUTTON.REMOVE')}>
				<DeleteRedeemableItems item={item} />
			</Tooltip>
		</div>
	)
}

const EditItemButton = ({
	action,
	disabled,
	tooltipText,
}: {
	action: () => void
	tooltipText: string
	disabled: boolean
}) => {
	const icon = useCallback(() => <Edit2 size="medium" fr={undefined} />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<IconButton
				data-testid="edit-redeemable-item"
				disabled={disabled}
				variant="tertiary"
				size="small"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}

const ReloadItemButton = ({
	action,
	tooltipText,
	disabled,
}: {
	action: () => void
	tooltipText: string
	disabled: boolean
}) => {
	const css = RedeemableItemsListStyle()

	const icon = useCallback(() => <RotateCw size="medium" fr={undefined} />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<TextButton
				data-testid="reload-redeemable-item"
				className={css.reloadButton}
				iconPosition="leading"
				size="medium"
				icon={icon}
				onClick={action}
				disabled={disabled}
			/>
		</Tooltip>
	)
}
