import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { ScreenName } from '@/domains'
import { getUserEmail } from '@/utils/tokenB2CHelper'
import { createEarningRule } from '../RulesService'
import { CreateEarningRuleDTO, EnuStatusRule } from '../RulesService.types'
import { handleEarningRulesError, normalizeSkus } from './earningRulesUtils'

export const useCreateEarningRule = (): {
	isLoading: boolean
	postCreateEarningRule: (payload: CreateEarningRuleDTO) => void
} => {
	const toast = useToast()
	const { t } = useTranslation()
	const settings = useCurrentSettings()
	const { vendorId: vendorIdSettings } = settings
	const { selectedTierKey, currentTier } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()
	const selectedZone = useCurrentZone()

	const { isLoading, mutate: postCreateEarningRule } = useMutation(
		(payload: CreateEarningRuleDTO) => {
			const skusWithVendorId = normalizeSkus({ items: payload.items, vendorIdSettings })

			const { amountSpent, points, description, status, vendorId, tier } = payload

			const payloadNormalized: CreateEarningRuleDTO = {
				amountSpent,
				points,
				description,
				status,
				items: skusWithVendorId,
				vendorId,
				tier,
			}
			return createEarningRule({ ...payloadNormalized, vendorId: vendorIdSettings, tier: selectedTierKey })
		},
		{
			onSuccess: async (response) => {
				const isDraft = response.status === EnuStatusRule.draft

				toast.notify({
					type: TypeToast.SUCCESS,
					message: isDraft
						? t('rules:SUCCESS_SAVE_DRAFT_RULE')
						: t('rules:CREATE_EARNING_RULES.CREATE_RULE_SUCCESSFUL_MESSAGE'),
				})
				await queryClient.invalidateQueries(['earningRules'])

				AnalyticsService.events.ruleCreated({
					amount: { value: response.amountSpent },
					audience_id: null,
					earning_rule_description: response.description,
					earning_rule_id: response.ruleId,
					points: String(response.points),
					rule_type: 'Segmented Earning Rule',
					tier: currentTier ? currentTier?.label : selectedTierKey,
					user: getUserEmail(),
					vendor: vendorIdSettings,
					zone: selectedZone,
					screen_name: ScreenName.CreateEarningRule,
				})

				goToRoutePath('/rules')
			},
			onError: (err: AxiosError) => {
				handleEarningRulesError({
					toast,
					err,
				})
			},
		},
	)

	return {
		isLoading,
		postCreateEarningRule,
	}
}
