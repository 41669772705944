import React, { useEffect } from 'react'
import AnalyticsService from 'services/analytics/AnalyticsService'
import { Tabs } from '@hexa-ui/components'
import ScreenName from 'domains/analytics/ScreenName'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { GridContainer } from '@/components/templates/GridTemplate'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { getMembershipStore } from '@/stores/global/GlobalStore'
import { TabHeader } from './components/TabHeader/TabHeader'
import RulesListStyle from './RulesListRedesign.styles'
import EarningRulesList from './EarningRulesList/EarningRulesList'
import ExclusionRulesList from './ExclusionRulesList/ExclusionRulesList'

const RulesListRedesign: React.FC = () => {
	const userInfo = useGetUserInfo()
	const classes = RulesListStyle()

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: ScreenName.EarningRules,
			...userInfo,
		})
	}, [userInfo])

	const defaultValueTab = getMembershipStore()?.defaultValueTab ?? 'tab-defaultEarningRules'

	return (
		<GridContainer>
			<PageLevelTemplate />
			<Tabs.Root defaultValue={defaultValueTab}>
				<Tabs.List>
					<TabHeader />
				</Tabs.List>
				<Tabs.Content value="tab-defaultEarningRules" className={classes.tabContent}>
					<EarningRulesList />
				</Tabs.Content>
				<Tabs.Content value="tab-exclusionList" className={classes.tabContent}>
					<ExclusionRulesList />
				</Tabs.Content>
			</Tabs.Root>
		</GridContainer>
	)
}

export default RulesListRedesign
