import React from 'react'
import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import useStyles from './ConfirmActionDialogStyles'

export type ButtonVariant = 'destructive' | 'secondary' | 'primary'

export interface IConfirmActionDialogButton {
	buttonText: string
	onClickAction?: (event: React.MouseEvent) => void
	key: string
	variant?: ButtonVariant
	loading?: boolean
}

export interface ConfirmActionDialogProps {
	open: boolean
	onClose: React.Dispatch<React.SetStateAction<boolean | undefined>>
	buttons: IConfirmActionDialogButton[] | undefined
}

const ConfirmActionDialog: React.FC<ConfirmActionDialogProps> = ({ open, onClose, buttons }) => {
	const { t } = useTranslation()

	return (
		<div data-testid="confirm-dialog">
			<Dialog.Root
				open={open}
				onOpenChange={onClose}
				actions={<Actions buttons={buttons} />}
				title={<Heading size="H3">{t('transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.TITLE')}</Heading>}
				variant="overlay"
			>
				<Paragraph>{t('transaction:TRANSACTION_DETAIL.CANCEL_TRANSACTION.DESCRIPTION')}</Paragraph>
			</Dialog.Root>
		</div>
	)
}

interface ActionsProps {
	buttons: IConfirmActionDialogButton[] | undefined
}

const Actions: React.FC<ActionsProps> = ({ buttons }) => {
	const css = useStyles()
	const { t } = useTranslation()

	return (
		<div className={css.wrapperButtons} data-testid="confirm-action">
			{buttons?.map((button) => (
				<Dialog.Close key={button.key}>
					<Button
						size="large"
						data-testid={`confirm-action-button-${button.key}`}
						onClick={button.onClickAction}
						variant={button.variant}
						isLoading={button.loading}
						disabled={button.loading}
					>
						{t(button.buttonText)}
					</Button>
				</Dialog.Close>
			))}
		</div>
	)
}

export default ConfirmActionDialog
