import MenuInteraction from '@/domains/analytics/MenuInteraction'
import { PermissionLevel } from '@/domains/enums'
import { useFeatureToggleV2 } from '@/hooks'
import {
	ChallengeHistoryDetailRoute,
	ChallengeRoute,
	ChallengesRoute,
	ComboRoute,
	CombosRoute,
	CreateEarningRuleWrapper,
	CreateExclusionListRoute,
	CreateRulePageV2Route,
	EditSkuRoute,
	RedeemableItemsRoute,
	RewardsPermissionRoute,
	RulesRoute,
	SelectProgramRoute,
	SelectTierRoute,
	SkusListRoute,
	TransactionHistoryRoute,
	TransactionRoute,
	TransactionsRoute,
	ZoneConfigurationRoute,
} from '@/routes'
import { BASE_ROUTE } from '@/utils/constants'
import * as FeatureToggle from '@/utils/featureToggle'
import {
	BankTransfer,
	BeerBottles,
	Crosshair,
	Currency,
	Lock,
	Receipt,
	Settings,
	TreeDiagram,
} from '@admin-portal-shared-components/icons'
import { GiftSparkle } from '@hexa-ui/icons'
import React from 'react'
import { RouteInstance } from '../domains/route/RouteInstance'
import CreateRuleRouter from './rules/CreateRuleRouter'

const END_STRING = '(/|$)'
const END_STRING_PLURAL = '(s|/|$)'

export const Rulesv2DumbRoute = () => <></>

export const useGetRoutes = () => {
	const isShowExclusionListEnabled = useFeatureToggleV2(FeatureToggle.SHOW_EXCLUSION_LIST)
	const devUseRulesV2MFE = useFeatureToggleV2(FeatureToggle.devUseRulesV2MFE)

	const routes: { [key: string]: RouteInstance } = {
		SELECT_PROGRAM_ROUTE: new RouteInstance({
			id: 'select-program',
			textTranslationKey: 'Select Program',
			mainRouteIndex: 0,
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/select-program${END_STRING}`),
			routeList: [
				{
					key: 'selectProgramRoute',
					path: `${BASE_ROUTE}/select-program`,
					component: SelectProgramRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'Select Program',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.SELECT_PROGRAM,
				feature: 'SelectProgram',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 100,
			renderIcon: () => <TreeDiagram size="large" />,
		}),

		SELECT_TIER_ROUTE: new RouteInstance({
			id: 'select-tier',
			textTranslationKey: 'settings:MENUS.TIER',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/select-tier${END_STRING}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'selectTierRoute',
					path: `${BASE_ROUTE}/select-tier`,
					component: SelectTierRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'Select Tier',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.SELECT_TIERS,
				feature: 'SelectProgram',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 200,
			renderIcon: () => <TreeDiagram size="large" />,
		}),

		CHALLENGE_ROUTE: new RouteInstance({
			id: 'challenge-history',
			textTranslationKey: 'challenge:MENU',
			analyticsCategory: MenuInteraction.ChallengeHistory,
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/challenge(s|$|/.*)`),
			mainRouteIndex: 1,
			routeList: [
				{
					key: 'ChallengeHistoryDetail',
					path: `${BASE_ROUTE}/challenges/:id`,
					component: ChallengeHistoryDetailRoute,
					textTranslationKey: 'challenge:HISTORY_DETAIL_REDESIGN.DETAIL_TITLE',
					breadCrumbsFlux: [1, 0],
				},
				{
					key: 'challengeHistoryRoute',
					path: `${BASE_ROUTE}/challenges`,
					component: ChallengesRoute,
					textTranslationKey: 'challenge:HISTORY.TITLE',
					breadCrumbsFlux: [1],
				},
				{
					key: 'challengeCreationRoute',
					path: `${BASE_ROUTE}/challenge/:step`,
					component: ChallengeRoute,
					textTranslationKey: 'challenge:FLOW_NAME',
					breadCrumbsFlux: [1, 2],
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.CHALLENGES_MODULE,
				feature: 'Challenge',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 300,
			renderIcon: () => <Crosshair size="large" />,
		}),

		TRANSACTION_ROUTE: new RouteInstance({
			id: 'transaction-log',
			textTranslationKey: 'transaction:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/transaction${END_STRING_PLURAL}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'transactionLogRoute',
					path: `${BASE_ROUTE}/transactions`,
					component: TransactionsRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'transaction:LOG.TITLE_PAGE',
				},
				{
					key: 'transactionCreationRoute',
					path: `${BASE_ROUTE}/transaction`,
					component: TransactionRoute,
					breadCrumbsFlux: [0, 1],
					textTranslationKey: 'transaction:TITLE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.POINTS_MANAGEMENT_MODULE,
				feature: 'PointsManagement',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 400,
			renderIcon: () => <BankTransfer size="large" />,
		}),

		COMBO_ROUTE: new RouteInstance({
			id: 'dt-combos',
			textTranslationKey: 'combo:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/combo${END_STRING_PLURAL}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'comboCreationRoute',
					path: `${BASE_ROUTE}/combos`,
					component: CombosRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'combo:COMBOS_PAGE_TITLE',
				},
				{
					key: 'combosListRoute',
					path: `${BASE_ROUTE}/combo`,
					component: ComboRoute,
					breadCrumbsFlux: [0, 1],
					textTranslationKey: 'combo:TITLE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.COMBOS_MANAGEMENT_MODULE,
				feature: 'DTComboManagement',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 500,
			renderIcon: () => <BeerBottles size="large" />,
		}),

		REDEEMABLE_ITEMS_ROUTE: new RouteInstance({
			id: 'redeemable-items',
			textTranslationKey: 'redeemable:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/redeemable-item${END_STRING_PLURAL}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'RedeemableItemsListRoute',
					path: `${BASE_ROUTE}/redeemable-items`,
					component: RedeemableItemsRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'redeemable:PAGE_TITLE',
					hideTierInfo: true,
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.REDEEMABLE_ITEMS_MODULE,
				feature: 'DTComboManagement',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 500,
			renderIcon: () => <GiftSparkle size="large" />,
		}),

		EARNING_RULES_ROUTE: new RouteInstance({
			id: 'earning-rules',
			textTranslationKey: 'rules:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/rules($|\\/\\S+)`),
			mainRouteIndex: 6,
			routeList: [
				{
					key: 'createRule',
					path: `${BASE_ROUTE}/rules/create-rule`,
					component: isShowExclusionListEnabled ? CreateRulePageV2Route : CreateRuleRouter,
					breadCrumbsFlux: [6, 0],
					textTranslationKey: 'rules:CREATE_EARNING_RULES.CREATE_NEW_EARNING_RULE',
				},
				{
					key: 'createEarningRule',
					path: `${BASE_ROUTE}/rules/earning-rule`,
					component: CreateEarningRuleWrapper,
					breadCrumbsFlux: [6, 0, 1],
					textTranslationKey: 'rules:CREATE_EARNING_RULES.EARNING',
				},
				{
					key: 'createExclusionList',
					path: `${BASE_ROUTE}/rules/exclusion-rule`,
					component: CreateExclusionListRoute,
					breadCrumbsFlux: [6, 0, 2],
					textTranslationKey: 'rules:CREATE_EXCLUSION_RULES.TITLE',
				},
				{
					key: 'editSkuRoute',
					path: `${BASE_ROUTE}/rules/:ruleId/:ruleName/edit-sku`,
					component: EditSkuRoute,
					breadCrumbsFlux: [6, 5, 3],
					textTranslationKey: 'rules:EDIT_SKUS',
				},
				{
					key: 'editRuleRoute',
					path: `${BASE_ROUTE}/rules/:ruleId/:ruleName/edit-rule`,
					component: CreateRuleRouter,
					breadCrumbsFlux: [6, 5, 4],
					renderText: () => 'Earning Rules',
					textTranslationKey: 'rules:EDIT_EARNING_RULES.EDIT_EARNING_RULE',
				},
				{
					key: 'skusListRoute',
					path: `${BASE_ROUTE}/rules/:ruleId/:ruleName?`,
					component: SkusListRoute,
					breadCrumbsFlux: [6, 5],
					renderText: (params) => params.ruleName || 'Details',
					exact: false,
				},
				{
					key: 'rulesListRoute',
					path: `${BASE_ROUTE}/rules`,
					component: RulesRoute,
					breadCrumbsFlux: [6],
					textTranslationKey: 'rules:TITLE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.EARNING_RULES_MODULE,
				feature: 'EarningRules',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 600,
			renderIcon: () => <Currency size="large" />,
		}),

		TRANSACTION_HISTORY_ROUTE: new RouteInstance({
			id: 'transaction-history',
			textTranslationKey: 'history:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/history${END_STRING}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'transactionHistoryRoute',
					path: `${BASE_ROUTE}/history`,
					component: TransactionHistoryRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'history:TITLE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.TRANSACTION_HISTORY_MODULE,
				feature: 'TransactionHistory',
				permissionLevel: PermissionLevel.Read,
			},
			sortIndex: 700,
			renderIcon: () => <Receipt size="large" />,
		}),

		REWARDS_PERMISSION_ROUTE: new RouteInstance({
			id: 'rewards-permission',
			textTranslationKey: 'permission:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/permission${END_STRING}`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'rewardsPermissionRoute',
					path: `${BASE_ROUTE}/permission`,
					component: RewardsPermissionRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'permission:TITLE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.REWARDS_PERMISSION_MODULE,
				feature: 'RewardsPermission',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 800,
			renderIcon: () => <Lock size="large" />,
		}),

		ZONE_CONFIGURATION_ROUTE: new RouteInstance({
			id: 'zone-configuration',
			textTranslationKey: 'settings:MENUS.ZONE',
			mainRouteIndex: 0,
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/zone-configuration${END_STRING}`),
			routeList: [
				{
					key: 'zoneConfigurationRoute',
					path: `${BASE_ROUTE}/zone-configuration`,
					component: ZoneConfigurationRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'settings:MENUS.ZONE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.ZONE_CONFIGURATION_MODULE,
				feature: 'ZoneConfiguration',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 1000,
			renderIcon: () => <Settings size="large" />,
		}),
	}

	if (devUseRulesV2MFE) {
		routes.RULES_V2 = new RouteInstance({
			id: 'rules-v2',
			textTranslationKey: 'rules:MENU',
			subRouteRegExp: new RegExp(`^${BASE_ROUTE}/rules($|\\/\\S+)`),
			mainRouteIndex: 0,
			routeList: [
				{
					key: 'rules-v2',
					path: `${BASE_ROUTE}/rules-v2`,
					component: Rulesv2DumbRoute,
					breadCrumbsFlux: [0],
					textTranslationKey: 'rules:CREATE_EARNING_RULES.CREATE_NEW_EARNING_RULE',
				},
			],
			authorizationFeature: {
				featureName: FeatureToggle.EARNING_RULES_MODULE,
				feature: 'EarningRules',
				permissionLevel: PermissionLevel.Delete,
			},
			sortIndex: 600,
			renderIcon: /* istanbul ignore next */ () => <Currency size="large" />,
		})
	}

	return { routes }
}
