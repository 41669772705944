import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores/redeemableItems/CriticalPricePerPointStoreV2'
import { $editRedeemableItemStore, $pricePerPointConfigurationStore, EditRedeemableItemStoreEvents } from '@/stores/'
import { truncate } from '@/utils/string'
import { Alert, Button, Dialog, Heading } from '@hexa-ui/components'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { ScreenName } from '@/domains'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import { MODAL_CHANGE_DELAY } from '@/utils/constants'
import { unformat } from '@/utils/currencyHelper'
import { useAnalyticsServicePricePerPoints } from '@/hooks/analyticsService/useAnalyticsServiceEventPricePerPoints'
import { isCppOutOfLimits } from '@/usecase/redeemableItems/CriticalPricePerPointUseCaseV2'
import { EditRedeemableItemUseCase } from '@/usecase/redeemableItemsV2'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import EditRedeemableItemFormProvider from './EditRedeemableItemFormProvider'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import EditRedeemableItemStyle from './EditRedeemableItemStyle'
import EditRedeemableItemContent from './EditRedeemableItemContent'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'

export const EditRedeemableItem = () => {
	const isRedeemableApprovalFlowEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_APPROVAL_FLOW)

	const css = EditRedeemableItemStyle()
	const { t } = useTranslation()

	const state = useStore($editRedeemableItemStore)
	const { isOpened, item, hasError } = state

	const initialState = useMemo(() => {
		return { ...item, pricePerPoint: undefined }
	}, [item])

	const minimumPrice = useFormatCurrency(item.cppLimits?.min)
	const maximumPrice = useFormatCurrency(item.cppLimits?.max)
	const cppRange = `(${minimumPrice} - ${maximumPrice})`
	const formField = `input-ppp-${state.item?.id}`

	const onModalOpenChangeHandler = useCallback(
		() => (isOpened ? EditRedeemableItemStoreEvents.reset() : EditRedeemableItemStoreEvents.onOpen(item)),
		[isOpened, item],
	)

	const contentCssValue = {
		display: 'flex',
		flexDirection: 'column',
		margin: 0,
		padding: '16px 24px',
		gap: '16px',
	}

	return (
		<div className={css.modalAlertWrapper} data-testid="edit-redeemable-item-wrapper">
			<EditRedeemableItemFormProvider formField={formField}>
				<Dialog.Root
					actions={
						<Actions
							hasError={state.hasError}
							isPublishing={state.isPublishing}
							item={state.item}
							isRedeemableApprovalFlowEnabled={isRedeemableApprovalFlowEnabled}
						/>
					}
					title={<Heading cellPadding="30">{t('redeemable:EDIT_ITEM.TITLE')}</Heading>}
					open={isOpened}
					onOpenChange={onModalOpenChangeHandler}
					contentCss={contentCssValue}
				>
					<EditRedeemableItemContent item={initialState} />

					{isRedeemableApprovalFlowEnabled && (
						<Alert
							message={
								item.cppLimits
									? t('redeemable:EDIT.ALERT_MESSAGE', { cppRange })
									: t('redeemable:ADD.COST_PER_POINT_WITHOUT_RANGE')
							}
							type="warning"
							className={css.warningMessage}
						/>
					)}

					{hasError && (
						<div data-testid="edit-redeemable-item-error-message">
							<Alert type="error" message={t('settings:NOTIFICATION.EDIT.ERROR')} className={css.errorMessage} />
						</div>
					)}
				</Dialog.Root>
			</EditRedeemableItemFormProvider>
		</div>
	)
}

export const Actions = ({
	hasError,
	isPublishing,
	item,
	isRedeemableApprovalFlowEnabled,
}: {
	hasError: boolean
	isPublishing: boolean
	item: RedeemableItem
	isRedeemableApprovalFlowEnabled?: boolean
}) => {
	const css = EditRedeemableItemStyle()
	const { t } = useTranslation()
	const toast = useToast()
	const { data } = usePricePerPointConfiguration()
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)
	const shouldDisplayWarning = useRef<boolean>(false)

	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServicePPP } = useAnalyticsServicePricePerPoints()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
		watch,
	} = useFormContext()

	const watchPricePerPoint = unformat(watch(`input-ppp-${item.id}`))
	const invalidPricePerPoint = isDirty && !isValid

	useEffect(() => {
		if (isRedeemableApprovalFlowEnabled && item?.cppLimits)
			shouldDisplayWarning.current = isCppOutOfLimits(watchPricePerPoint, item.cppLimits)
	}, [isRedeemableApprovalFlowEnabled, watchPricePerPoint, item.cppLimits])

	const onClose = () => {
		sendAnalyticsServiceEventButtonClickedEvent('Edit Redeemable Price Per Point', 'Cancel', 'Cancel')
	}

	const displayToast = useCallback(() => {
		if (shouldDisplayWarning.current) {
			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT_ITEM.CPP_OUT_OF_RANGE'),
			})
		} else {
			toast.notify({
				type: TypeToast.SUCCESS,
				message: t('redeemable:NOTIFICATION.EDIT_ITEM.SUCCESS', {
					itemName: truncate(`${item.itemName}`),
				}),
			})
		}
	}, [item, toast, t])

	const onSubmit = useCallback(
		async (pricePerPoint: number) => {
			try {
				const redeemableItem = { ...item, pricePerPoint }
				const itemPricePerPoint = item.pricePerPoint! ?? defaultPricePerPoint
				await EditRedeemableItemUseCase.execute(
					redeemableItem,
					data!.pricePerPoint!,
					itemPricePerPoint,
					isRedeemableApprovalFlowEnabled,
				)
				sendAnalyticsServicePPP({ pricePerPoint, sku: item.vendorItemId, skuName: item.itemName })
				if (item)
					sendAuditLogRedeemables({
						pricePerPoint,
						SKU: item.vendorItemId,
						operation: IAuditOperations.UPDATE,
					})
				displayToast()
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				sendAnalyticsServiceEventError({ failureReason: error.message, screenName: ScreenName.EditRedeemablePPP })
			}
		},
		[
			item,
			isRedeemableApprovalFlowEnabled,
			sendAnalyticsServiceEventError,
			sendAnalyticsServicePPP,
			sendAuditLogRedeemables,
			displayToast,
		],
	)

	const buttonAction = useCallback(
		(pricePerPoints) => {
			const pricePerPoint = Object.values(pricePerPoints)[0] as string
			const unformattedPricePerPoint: number = unformat(pricePerPoint)
			if (item) EditRedeemableItemStoreEvents.setItem({ ...item, pricePerPoint: unformattedPricePerPoint })

			setTimeout(() => {
				CriticalPricePerPointConfigurationStoreEvents.onOpen({
					submit: () => {
						onSubmit(unformattedPricePerPoint)
					},
					close: () => {
						EditRedeemableItemStoreEvents.onClose()
					},
					skuPricePerPoint: unformattedPricePerPoint,
				})
			}, MODAL_CHANGE_DELAY)
		},
		[item, onSubmit],
	)

	return (
		<div className={css.actionsWrapper} data-testid="edit-redeemable-item-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" data-testid="edit-redeemable-item-cancel-button" onClick={onClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="edit-redeemable-item-save-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={handleSubmit(buttonAction)}
				disabled={invalidPricePerPoint}
				isLoading={!!isPublishing}
			>
				{hasError ? t('BUTTON.TRY_AGAIN') : t('BUTTON.SAVE')}
			</Button>
		</div>
	)
}
