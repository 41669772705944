import { TFunction } from 'react-i18next'
import ApiErrorResponse from '@/domains/ApiErrorResponse'

function FormatErrorMessage(error: ApiErrorResponse, t: TFunction<'translation', undefined>): string {
	const errorMessages: { [key: number]: () => string } = {
		35: () => t('redeemable:NOTIFICATION.API_ERROR.ADDED_REDEEMABLE_EXCEEDS_THE_REDEEMABLE_LIMIT'),
		36: () => {
			const pocId = error?.details
			return pocId && pocId?.length >= 1
				? t('transaction:NOTIFICATION.API_ERROR.POINTS_REMOVE_DUPLICATE_LINE', { pocId })
				: t('transaction:NOTIFICATION.API_ERROR.POINTS_REMOVE_DUPLICATE_LINE_GENERIC')
		},
		409: () => t('redeemable:NOTIFICATION.API_ERROR.REDEEMABLE_WITH_AN_OPEN_TRANSACTION'),
	}

	return errorMessages[error?.code]?.() ?? t('transaction:NOTIFICATION.ERROR')
}

export function getApiErrorMessage(error: Error | undefined, t: TFunction<'translation', undefined>): string {
	const errorMessage = error?.cause as ApiErrorResponse

	if (!errorMessage) {
		return t('transaction:NOTIFICATION.ERROR')
	}

	return FormatErrorMessage(errorMessage, t)
}
