import { SettingsLevel } from '@/domains/enums/settings'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useMemo } from 'react'

export type SelectedSettings = {
	settingsId: string
	level: SettingsLevel
	vendorId: string
}

const useCurrentSettings = (): SelectedSettings => {
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const settings = useMemo<SelectedSettings>(
		() => ({
			settingsId: vendorId,
			level: SettingsLevel.VENDOR,
			vendorId,
		}),
		[vendorId],
	)
	return settings
}

export default useCurrentSettings
