import { useCallback, useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { ScreenName } from '@/domains'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'

import { $redeemableItemsStore, redeemableItemsInitialState, RedeemableItemsStoreEvents } from '@/stores'
import { LoadRedeemableItemsUseCase } from '@/usecase'
import { ILoadRedeemableItemsUseCaseParams } from '@/usecase/redeemableItems/LoadRedeemableItemsUseCase'

import { useAnalyticsServiceEventPageViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventPageViewed'
import { useAnalyticsServiceEventPaginationClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventPaginationClicked'
import { useAnalyticsServiceEventTableItemsPerPageChanged } from '@/hooks/analyticsService/useAnalyticsServiceEventTableItemsPerPageChanged'
import { useAnalyticsServiceEventTableSearched } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSearched'
import { useAnalyticsServiceEventTableSorted } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSorted'
import { useAnalyticsServiceEventTableViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventTableViewed'

import { BeesTableSortType } from '@/components/tables/DataTable/model/TableOrderType'

import { TYPING_CHANGE_DEBOUNCE_DELAY } from '@/utils/constants'
import useDebounce from '@/utils/useDebounce'
import { RedeemableItemLimitStoreEvents } from '@/stores/redeemableItems/RedeemableItemLimitStore'

export type UseRedeemableItemsListResult = {
	isLoading: boolean
	hasError: boolean
	data: RedeemableItem[]
	onSearchChanged: (value: string) => void
	onSortChanged: (column?: string, orderDirection?: BeesTableSortType) => void
	onPaginationChanged: (page: number, pageSize: number) => void
}

const DEFAULT_SORT_BY = 'status'
const DEFAULT_SORT_ORDER = 'DESC'
const DEFAULT_SORTING = `${DEFAULT_SORT_BY}_${DEFAULT_SORT_ORDER}`

export const useRedeemableItemsList = (): UseRedeemableItemsListResult => {
	const { content: contentData, reloadPageAtTime } = useStore($redeemableItemsStore)

	const [isLoading, setIsLoading] = useState(true)
	const [hasError, setHasError] = useState(false)
	const [search, setSearch] = useState<string>('')
	const [orderBy, setOrderBy] = useState<string>(DEFAULT_SORTING)

	const debouncedSearchData = useDebounce(search, TYPING_CHANGE_DEBOUNCE_DELAY)

	const { sendAnalyticsServiceEventPageViewed } = useAnalyticsServiceEventPageViewed()
	const { sendAnalyticsServiceTableViewed } = useAnalyticsServiceEventTableViewed()
	const { sendAnalyticsServiceTableSearched } = useAnalyticsServiceEventTableSearched()
	const { sendAnalyticsServiceTableItemsPerPageChanged } = useAnalyticsServiceEventTableItemsPerPageChanged()
	const { sendAnalyticsServiceTableSorted } = useAnalyticsServiceEventTableSorted()
	const { sendAnalyticsServicePaginationClicked } = useAnalyticsServiceEventPaginationClicked()

	useEffect(() => {
		sendAnalyticsServiceEventPageViewed(ScreenName.RedeemablesList)
	}, [sendAnalyticsServiceEventPageViewed])

	const loadItems = useCallback(async () => {
		const { pagination: storePagination } = $redeemableItemsStore.getState()
		const [sortBy, sortingOrder] = orderBy.split('_')

		const params: ILoadRedeemableItemsUseCaseParams = {
			sortBy: sortBy || undefined,
			sortingOrder: sortingOrder || undefined,
			vendorItemId: debouncedSearchData,
			page: storePagination.page,
			pageSize: storePagination.pageSize,
		}

		setIsLoading(true)
		setHasError(false)

		try {
			const { content = [], pagination: resPagination } = await LoadRedeemableItemsUseCase.execute({
				...params,
			})

			sendAnalyticsServiceTableViewed({
				itemsPerPage: String(resPagination.pageSize),
				itemsTotal: String(resPagination.totalElements),
				pageNumber: `${resPagination.page + 1}`,
				screenName: ScreenName.RedeemablesList,
				tableName: 'Redeemables List',
			})

			RedeemableItemsStoreEvents.setContent(content)
			RedeemableItemsStoreEvents.setPagination(resPagination)

			const totalRedeemableItems = params.vendorItemId
				? await LoadRedeemableItemsUseCase.execute({ ...params, vendorItemId: '' }).then(
						(redeemableItems) => redeemableItems.pagination.totalElements,
					)
				: resPagination.totalElements

			RedeemableItemLimitStoreEvents.updateTotalRedeemableItems(totalRedeemableItems)
		} catch (error) {
			setHasError(true)

			RedeemableItemsStoreEvents.setContent([])
			RedeemableItemsStoreEvents.setPagination(redeemableItemsInitialState.pagination)
		} finally {
			setIsLoading(false)
		}
	}, [debouncedSearchData, orderBy, sendAnalyticsServiceTableViewed])

	const onSearchChanged = useCallback(
		(value: string) => {
			setSearch(value)
			sendAnalyticsServiceTableSearched({
				searchQuery: value,
				contentType: 'Sku',
				tableName: 'Redeemables List',
				screenName: 'Redeemables List',
			})
		},
		[sendAnalyticsServiceTableSearched],
	)

	const onSortChanged = useCallback(
		(column?: string, orderDirection?: BeesTableSortType) => {
			if (!column || !orderDirection) {
				setOrderBy('')
				return
			}

			const newOrderBy = `${column}_${orderDirection.toUpperCase()}`
			setOrderBy(newOrderBy)
			sendAnalyticsServiceTableSorted({
				sortCriteria: column,
				sortType: orderDirection,
				tableName: 'Redeemables List',
				screenName: 'Redeemables List',
			})
		},
		[sendAnalyticsServiceTableSorted],
	)

	const onPaginationChanged = useCallback(
		(page: number, pageSize: number) => {
			// The initial page on the View (<Table> component) is 1 and on the ms it's 0 based.
			const validPage = Math.max(0, page - 1)
			RedeemableItemsStoreEvents.setPagination({ page: validPage, pageSize })
			RedeemableItemsStoreEvents.reloadPage()
			sendAnalyticsServicePaginationClicked({
				interactionType: 'Page changed',
				pageNumber: page.toString(),
				screenName: 'Redeemables List',
			})
			sendAnalyticsServiceTableItemsPerPageChanged({
				itemsPerPage: pageSize.toString(),
				itemsTotal: String(contentData.length),
				pageNumber: page.toString(),
				screenName: ScreenName.RedeemablesList,
				tableName: 'Redeemables List',
			})
		},
		[contentData, sendAnalyticsServicePaginationClicked, sendAnalyticsServiceTableItemsPerPageChanged],
	)

	useEffect(() => {
		RedeemableItemsStoreEvents.setPagination(redeemableItemsInitialState.pagination)

		return () => {
			RedeemableItemsStoreEvents.reset()
		}
	}, [])

	useEffect(() => {
		loadItems()
	}, [loadItems, reloadPageAtTime])

	return {
		isLoading,
		hasError,
		data: contentData,
		onSearchChanged,
		onSortChanged,
		onPaginationChanged,
	}
}
