import * as RedeemablesService from '@/services/redeemablesV2/RedeemablesService'
import { useLogService as logService } from 'admin-portal-shared-services'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { SettingsLevel } from '@/domains/enums/settings'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'
import { ChangeConfigParams, updateConfiguration } from '@/services/settings/SettingsService'

async function execute(
	pricePerPoint: number,
	defaultCpp: number,
	isRedeemableApprovalFlowEnabled?: boolean,
): Promise<void> {
	const log = logService()
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const requestDataV1: ChangeConfigParams = {
		settingsId: vendorId,
		level: SettingsLevel.VENDOR,
		tiers: [DEFAULT_TIER_FOR_PPP],
	}

	const requestDataV2 = {
		pricePerPoint: +pricePerPoint,
		vendorId,
		defaultCpp,
	}

	try {
		const updateDefaultCpp = isRedeemableApprovalFlowEnabled
			? RedeemablesService.updateCpp(requestDataV2, 'DEFAULT')
			: updateConfiguration(requestDataV1, {
					pricePerPoint,
				})
		await updateDefaultCpp
	} catch (error) {
		log.error('FAILED TO UPDATE DEFAULT CPP: ', error)
		throw error
	}
}

export { execute }
