import { useCallback } from 'react'
import { ComboEvents } from '@/stores'
import { ComboType, RedeemableStatus } from '@/domains/enums'
import { Redeemable } from '@/domains'
import { LoadRedeemableByStatusUseCase, LoadRedeemableUseCase } from '@/usecase'
import * as FeatureToggle from '@/utils/featureToggle'
import { useIsMountedRef } from '../mountedRef/useIsMountedRef'
import { useFeatureToggleV2 } from '../toggle/useFeatureToggle'

type RedeemableList = Array<Redeemable<ComboType>>

export interface LoadRedeemablesAndCombos {
	loadRedeemablesAndCombos: (programId: string) => Promise<RedeemableList>
	loadRedeemableByStatus: (status: Array<RedeemableStatus>) => Promise<void>
}

const useLoadRedeemablesAndCombos = (): LoadRedeemablesAndCombos => {
	const isToggleCombos3PDIntegrationEnabled = useFeatureToggleV2(FeatureToggle.COMBOS_3PD_INTEGRATION)

	const isMountedRef = useIsMountedRef()

	const loadRedeemablesAndCombos = useCallback(async (): Promise<RedeemableList> => {
		try {
			const result = await LoadRedeemableUseCase.execute({
				isToggleCombos3PDIntegrationEnabled: isToggleCombos3PDIntegrationEnabled!,
			})
			const redeemables = Object.values(result.redeemablesMap)
			ComboEvents.setCombos({ ...result, showError: false })
			return redeemables
		} catch {
			ComboEvents.showErrorInCombosData()
			return []
		}
	}, [isToggleCombos3PDIntegrationEnabled])

	const loadRedeemableByStatus = useCallback(
		async (status: Array<RedeemableStatus>) => {
			try {
				const result = await LoadRedeemableByStatusUseCase.execute({
					status,
					isToggleCombos3PDIntegrationEnabled: isToggleCombos3PDIntegrationEnabled!,
				})
				if (isMountedRef.current) {
					ComboEvents.setCombos({ ...result, showError: false })
				}
			} catch (error) {
				ComboEvents.showErrorInCombosData()
				throw error
			}
		},
		[isMountedRef, isToggleCombos3PDIntegrationEnabled],
	)

	return {
		loadRedeemablesAndCombos,
		loadRedeemableByStatus,
	}
}

export default useLoadRedeemablesAndCombos
