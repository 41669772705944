import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useStore } from 'effector-react'
import { $createRedeemableItemStore } from '@/stores'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { Grid, TextButton } from '@hexa-ui/components'
import {
	CREATE_REDEEMABLE_LIMIT,
	MANAGE_REDEEMABLE,
	REDEEMABLE_DEFAULT_CPP,
	REDEEMABLE_SKU_CPP,
} from '@/utils/constants'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { syncCombo } from '@/services/redeemables/RedeemablesService'
import { Sync } from '@material-ui/icons'
import { ViewState } from '@/domains/redeemable/ViewState'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { $redeemableItemLimitStore } from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { useRedeemableItemsList } from './hooks/useRedeemableItemsList'
import { useRedeemableItemActions } from './hooks/useRedeemableItemActions'
import { RedeemableItemsTable } from './components/RedeemableItemsTable/RedeemableItemsTable'
import { EditRedeemableItem } from '../EditRedeemableItemV2/EditRedeemableItem'
import RedeemableItemsListStyle from './RedeemableItemsListStyle'
import { ExportRedeemableItemButton } from './components/ExportRedeemableItemButton/ExportRedeemableItemButton'
import { RedeemableItemsEmptyState } from './components/RedeemableItemsEmptyState/RedeemableItemsEmptyState'
import { AddRedeemableDialog } from '../AddRedeemableDialog/AddRedeemableDialog'
import { CreateRedeemableItemButton } from './components/CreateRedeemableItemButton/CreateRedeemableItemButton'

import { LimitExceededCard } from './components/LimitExceededCard/LimitExceededCard'
import { LimitUpdatedExceededDialog } from './components/LimitUpdatedExceededDialog/LimitUpdatedExceededDialog'
import { DefaultCpp } from '../DefaultCppV2/DefaultCpp'

export const RedeemableItemsList = (props: HTMLAttributes<HTMLDivElement>) => {
	const isRedeemableRestrictCppVisibilityEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_RESTRICT_CPP_VISIBILITY)
	const is3pd = useFeatureToggleV2(FeatureToggle.REDEEMABLE_SYNC_3PD)

	const hasSKUCPPPermission = useScopeAuthorization([REDEEMABLE_SKU_CPP])
	const hasCreateRedeemableLimitPermission = useScopeAuthorization([CREATE_REDEEMABLE_LIMIT])
	const hasRedeemablesDefaultCPPPermission = useScopeAuthorization([REDEEMABLE_DEFAULT_CPP])
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])

	const { isOpened } = useStore($createRedeemableItemStore)
	const { limit } = useStore($redeemableItemLimitStore)

	const { isLoading, hasError, data, ...useProps } = useRedeemableItemsList()

	const { genericToastError } = useRedeemableItemActions()

	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [viewState, setViewState] = useState(ViewState.LOADING)

	const toast = useToast()
	const { t } = useTranslation()
	const css = RedeemableItemsListStyle()

	const canEditSKU = hasRedeemablesDefaultCPPPermission || hasSKUCPPPermission || hasManageRedeemablePermission

	const notifyUser = (status: number) => {
		const messageKey = status === 200 || status === 201 ? 'redeemable:REFRESH.SUCCESS' : 'redeemable:REFRESH.FAIL'

		toast.notify({
			type: status === 200 || status === 201 ? TypeToast.SUCCESS : TypeToast.ERROR,
			message: t(messageKey),
		})
	}

	const syncRedeemClick = async () => {
		try {
			const { status } = await syncCombo()
			notifyUser(status)
		} catch {
			notifyUser(0)
		}
	}

	useEffect(() => {
		if (isLoading) {
			setViewState(ViewState.LOADING)
			return
		}

		if (hasError) {
			setViewState(ViewState.ERROR)
			return
		}

		if (isFirstLoad && data.length <= 0 && limit) {
			setViewState(ViewState.EMPTY)
			return
		}

		if (!limit && hasCreateRedeemableLimitPermission && isRedeemableRestrictCppVisibilityEnabled) {
			setViewState(ViewState.NOLIMIT)
			return
		}

		setIsFirstLoad(false)
		setViewState(ViewState.DONE)
	}, [
		isLoading,
		hasCreateRedeemableLimitPermission,
		hasManageRedeemablePermission,
		isRedeemableRestrictCppVisibilityEnabled,
		limit,
		hasError,
		data,
		viewState,
		isFirstLoad,
	])

	useEffect(() => {
		if (viewState !== ViewState.ERROR) {
			return
		}

		genericToastError()
	}, [viewState, genericToastError])

	if (isFirstLoad && [ViewState.LOADING, ViewState.EMPTY, ViewState.NOLIMIT, ViewState.ERROR].includes(viewState)) {
		return (
			<Grid.Item data-testid="redeemable-items-empty-state" className={css.mainWrapper} {...props}>
				<DefaultCpp hasRedeemablesDefaultCPPPermission={hasRedeemablesDefaultCPPPermission} />
				<RedeemableItemsEmptyState viewState={viewState} canCreateSKU={canEditSKU} />
			</Grid.Item>
		)
	}

	return (
		<Grid.Item data-testid="redeemable-items-list-v2" className={css.mainWrapper} {...props}>
			<LimitUpdatedExceededDialog />
			<LimitExceededCard />
			<EditRedeemableItem />
			<Grid.Item className={css.headerWrapper}>
				<ExportRedeemableItemButton />
				{canEditSKU && <AddRedeemableDialog isOpened={isOpened} trigger={<CreateRedeemableItemButton />} />}
			</Grid.Item>
			<DefaultCpp hasRedeemablesDefaultCPPPermission={hasRedeemablesDefaultCPPPermission} />
			<RedeemableItemsTable viewState={viewState} data={data} {...useProps} />
			{is3pd && (
				<TextButton
					onClick={syncRedeemClick}
					icon={Sync}
					iconPosition="leading"
					style={{
						marginTop: '-41px',
						alignSelf: 'flex-start',
						marginLeft: '14px',
					}}
					data-testid="redeemable-items-list-refresh-button"
				>
					{t('redeemable:REFRESH.BUTTON')}
				</TextButton>
			)}
		</Grid.Item>
	)
}
