import { Redeemable } from '@/domains'
import { ComboType, RedeemableStatus, RedeemableType } from '@/domains/enums'
import Combo from '@/domains/combo/Combo'
import { createIdMapFromArray } from '@/utils/mappingUtil'
import * as RedeemablesService from '@/services/redeemables/RedeemablesService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { convertToRedeemables } from '@/utils/redeemable'
import { RedeemablesMap } from '@/domains/redeemable/Redeemable'
import { hasText } from '@/utils/string'
import * as LoadComboUseCase from '../combo/LoadComboUseCase'

type RedeemableComboType = Redeemable<ComboType>

const defaultParams = { type: RedeemableType.Combo, pageSize: 900000 }

const loadComboIdsMap = async (): Promise<Record<string, Combo>> => {
	const combos = await LoadComboUseCase.executeByVendorComboIds()
	return createIdMapFromArray('id', combos)
}

const loadFromRedeemablesService = async (isToggleCombos3PDIntegrationEnabled: boolean): Promise<Array<Redeemable>> => {
	const vendorId = getDefaultVendorIdOfCurrentZone()
	const { content } = await RedeemablesService.load(
		{ vendorIds: [vendorId], ...defaultParams },
		isToggleCombos3PDIntegrationEnabled,
	)
	return convertToRedeemables(content)
}

const loadRedeemableList = async ({
	isToggleCombos3PDIntegrationEnabled,
}: {
	isToggleCombos3PDIntegrationEnabled: boolean
}): Promise<Array<Redeemable>> => {
	return loadFromRedeemablesService(isToggleCombos3PDIntegrationEnabled)
}

const addExtraInfoToRedeemable = (redeemable: Redeemable | RedeemableComboType, combo?: Combo): RedeemableComboType => {
	const comboName = combo ? combo.title : undefined
	const newCombo: RedeemableComboType = {
		...redeemable,
		type: (combo?.type ?? redeemable.type) as ComboType,
		name: comboName,
		vendorComboId: combo?.vendorComboId,
		status: hasText(comboName) ? RedeemableStatus.PUBLISHED : RedeemableStatus.UNPUBLISHED,
	}
	return newCombo
}

export async function execute({
	isToggleCombos3PDIntegrationEnabled,
}: {
	isToggleCombos3PDIntegrationEnabled: boolean
}): Promise<{
	redeemablesMap: RedeemablesMap
}> {
	const prevRedeemables = await loadRedeemableList({
		isToggleCombos3PDIntegrationEnabled,
	})
	const comboIdsMap = await loadComboIdsMap()
	const newRedeemables = prevRedeemables.map((redeemableItem: Redeemable) =>
		addExtraInfoToRedeemable(redeemableItem, comboIdsMap[redeemableItem.redeemableId]),
	)
	const redeemablesMap = createIdMapFromArray('redeemableId', newRedeemables)
	return {
		redeemablesMap,
	}
}
