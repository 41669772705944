import { RewardsOfferType, TransactionType } from '@/domains/enums'
import TransparencyTransaction from 'domains/transparency/TransparencyTransaction'
import { createEvent } from 'effector'
import { FormikValues } from 'formik'
import { OrderWithCombosOrRedeemables } from './TransactionState'

export const selectOrder = createEvent<OrderWithCombosOrRedeemables | null>('selectOrder')
export const showNotificationForTransaction = createEvent<'success' | 'error' | null>('showNotification')
export const setShowErrorForTransaction = createEvent<boolean>('setShowError')
export const setShowCustomErrorMessageForTransaction = createEvent<Error | undefined>('setShowCustomErrorMessage')
export const setTransactionType = createEvent<TransactionType | null>('setTransactionType')
export const setIsSubmitVisible = createEvent<boolean>('setIsSubmitVisible')
export const setIsSubmitEnabled = createEvent<boolean>('setIsSubmitEnabled')
export const setRewardsOfferType = createEvent<RewardsOfferType | null>('setRewardsOfferType')
export const showActivityLogDialog = createEvent<{
	id: string
	failedPocs: TransparencyTransaction[]
	description: string
}>('showActivityLogDialog')
export const hideActivityLogDialog = createEvent('hideActivityLogDialog')
export const resetForm = createEvent<FormikValues>('resetForm')
export const setConfirmationMessage = createEvent<string>('setConfirmationMessage')
export const setOriginAccountBalance = createEvent<string>('setOriginAccountBalance')
export const setPointsRemoval = createEvent<{
	currentPointsBalance?: string | JSX.Element
	newPointsBalance?: string | JSX.Element
}>('setPointsRemoval')
export const setRemovalForServiceUtility = createEvent<{
	currentPointsBalance?: string
	newPointsBalance?: string
}>('setPointsRemovalForServiceUtility')
export const resetForTransaction = createEvent('reset')

export const addMapToAccountIdTranslationMap = createEvent<Record<string, string>>('addMapToAccountIdTranslationMap')
