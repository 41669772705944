import { InputCurrencyLegacy } from '@/components/hook-form/InputCurrency'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import { SettingDefaultConfiguration } from '@/domains/settings'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import { SettingsConfigurationStore } from '@/stores'
import * as currencyHelper from '@/utils/currencyHelper'
import { useIsTogglePricePerPointSettingsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import {
	fieldCurrencyValidationRequired,
	fieldInitialBalanceValidation,
	fieldNumberValidationRequiredMin1,
} from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import InputNumber from 'components/hook-form/InputNumber'
import { useStore } from 'effector-react'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useIsEditingAllowed } from '../../hooks/useIsEditingAllowed'

type Props = {
	showDialog: boolean
	onCancel: () => void
	onSubmit: (formData: SettingDefaultConfiguration) => void
}
const FormDialog: React.FC<Props> = ({ showDialog, onCancel, onSubmit }) => {
	const { t } = useTranslation()
	const { settingsData } = useStore(SettingsConfigurationStore)
	const { data: pppSettingsData } = usePricePerPointConfiguration()
	const { isPricePerPointAllowed } = useIsEditingAllowed()
	const isPricePerPointFeatureEnabled = useIsTogglePricePerPointSettingsEnabled()

	const schema = Yup.object().shape({
		initialBalance: fieldInitialBalanceValidation(),
		redeemLimit: fieldNumberValidationRequiredMin1(),
		pricePerPoint:
			isPricePerPointAllowed && isPricePerPointFeatureEnabled
				? fieldCurrencyValidationRequired()
				: Yup.string().optional(),
	})

	const { handleSubmit, control, formState } = useForm({
		resolver: yupResolver(schema),
		mode: 'all',
		shouldFocusError: true,
		defaultValues: {
			...settingsData,
			pricePerPoint: currencyHelper.format(pppSettingsData?.pricePerPoint),
		},
	})

	const { isDirty, isValid } = formState

	return (
		<FormDialogTemplate
			showDialog={showDialog}
			title={t('settings:FORM.TITLE')}
			cancelButtonProps={{ handleClick: onCancel }}
			submitButtonProps={{ disabled: !isDirty || !isValid }}
			handleSubmit={handleSubmit(onSubmit)}
		>
			<Controller
				control={control}
				name="initialBalance"
				render={({ field, fieldState: { error } }) => (
					<InputNumber
						{...field}
						id="settings-initial-balance"
						label={t('settings:LABEL_NAME.INITIAL_BALANCE')}
						errorText={error?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="redeemLimit"
				render={({ field, fieldState: { error } }) => (
					<InputNumber
						{...field}
						id="settings-redeem-limit"
						label={t('settings:LABEL_NAME.REDEEM_LIMIT')}
						errorText={error?.message}
					/>
				)}
			/>
			{!!isPricePerPointFeatureEnabled && (
				<Controller
					control={control}
					name="pricePerPoint"
					render={({ field, fieldState: { error } }) => (
						<InputCurrencyLegacy
							{...field}
							id="settings-price-per-point"
							label={t('settings:LABEL_NAME.PRICE_PER_POINT', {
								currencySymbol: currencyHelper.getSymbol(),
							})}
							errorText={error?.message}
							disabled={!isPricePerPointAllowed}
						/>
					)}
				/>
			)}
		</FormDialogTemplate>
	)
}
export default FormDialog
