import React, { memo } from 'react'
import { Tabs } from '@hexa-ui/components'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useTranslation } from 'react-i18next'
import { EnumTabsRulesType, getMembershipStore, setMembershipStore } from '@/stores/global/GlobalStore'

export const TabHeader: React.FC = memo(() => {
	const isShowExclusionListEnabled = useFeatureToggleV2(FeatureToggle.SHOW_EXCLUSION_LIST)
	const { t } = useTranslation()
	const data = getMembershipStore()

	const handleClick = (value: EnumTabsRulesType) => {
		setMembershipStore({
			...data,
			defaultValueTab: value,
		})
	}

	return (
		<>
			<Tabs.Trigger
				onClick={() => handleClick(EnumTabsRulesType.TAB_EARNING_RULES)}
				value={EnumTabsRulesType.TAB_EARNING_RULES}
			>
				{t('rules:EARNING_LIST')}
			</Tabs.Trigger>
			{isShowExclusionListEnabled && (
				<Tabs.Trigger
					onClick={() => handleClick(EnumTabsRulesType.TAB_EXCLUSION_LIST)}
					value={EnumTabsRulesType.TAB_EXCLUSION_LIST}
				>
					{t('rules:EXCLUSION_LIST')}
				</Tabs.Trigger>
			)}
		</>
	)
})
